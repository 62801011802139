import {IO} from "../index";
import {useEffect, useRef, useState} from "react";
import {io} from "socket.io-client";
import TokenStorage from '../API/TokenStorage';
import { configureTransport, getTransport } from '../API/transport';
import { logout, setTokens } from '../redux/slices/auth';
import { get } from '../API/request';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function useChat() {

    const {current: socket} = useRef(IO);

    const accessToken = TokenStorage.getAccessToken();
    const roomId = TokenStorage.getRoomId();

    const [users, setUsers] = useState([]);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState([]);

    const [log, setLog] = useState(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {

        socket.on("log", (log) => {
            setLog(log);
        });

        socket.on("userList:update", (users) => {
            setUsers(users);
            // console.log(users);
        });

        socket.on("exception", async (exeception) => {
            console.log(exeception);
            if (exeception?.status === 401) {
                try {
                    const res = await get('refresh',{
                        headers: {
                            'Authorization': `Bearer ${TokenStorage.getRefreshToken()}`,
                        }
                    });

                    await configureTransport(res.data.tokens.accessToken)
                    dispatch(setTokens({
                        accessToken: res.data.tokens.accessToken,
                        refreshToken: res.data.tokens.refreshToken,
                    }));

                    await TokenStorage.setAccessToken(res.data.tokens.accessToken);
                    await TokenStorage.setRefreshToken(res.data.tokens.refreshToken);
                    await TokenStorage.setTokenReceived(Date.now() / 1000);

                } catch (e) {
                    TokenStorage.logOut();
                    logout(dispatch)
                    navigate('/login')
                    // throw e;
                    console.log(e)
                }
            } else {
                console.log(exeception.message);
            }
        })

        socket.on("messageList:update", (messages) => {
            setMessages(messages);
            // console.log(messages);
        });

        socket.on("messageList:new", (messages) => {
            setNewMessage(messages);
            // console.log(messages);
        });
    }, []);

    const joinRoom = (roomId) => {
        socket.emit("room:join", {
            roomId: roomId,
            accessToken: accessToken,
        });
    };

    const onLoadNewMessages = ({roomId, lastMessageId, limit}) => {
        socket.emit("messageList:get", {
            roomId: roomId,
            lastMessageId: lastMessageId,
            limit: limit,
        });
    };

    const sendMessage = (message) => {
        socket.emit("message:add", message);
    };

    const readMessage = (message) => {
        socket.emit("message:read", message);
    };

    return {users, messages, log, sendMessage, readMessage, joinRoom, onLoadNewMessages, roomId, newMessage};
}
