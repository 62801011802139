import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import {
    Box, Button,
    Container
} from '@material-ui/core';
import '../../styles/All.css';
import '../../styles/Chat/style.css';
import TokenStorage from '../../API/TokenStorage';
import Conversation from '../../components/Chat/conversations/Conversation';
import Message from '../../components/Chat/message/Message';
import Input from '../../components/Chat/input/Input';
import ArrowToRight from '../../icons/ArrowToLeft';
import ArrowToLeft from '../../icons/ArrowToLeft';
import ChatHeader from '../../components/Chat/ChatHeader/ChatHeader';
import useChat from '../../hooks/useChat';
import useRooms from '../../hooks/useRooms';
import { usePost } from '../../API/request';
import { useInView } from 'react-intersection-observer';
import { makeid } from '../../help/randowGenerator';
import { Link } from 'react-router-dom';
import {
    BarChart as BarChartIcon,
    Bell,
    BellOff
} from 'react-feather';
import { BallTriangle } from 'react-loader-spinner';
import SubscriptionLogic from '../../components/Chat/SubscriptionLogic/SubcriptionLogic';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from '../../firebase';
import { getMessaging, getToken } from 'firebase/messaging';
import { setFirebaseToken } from '../../redux/slices/auth';
import { useDispatch } from 'react-redux';
import { getDeviceTokenForFirebase } from '../../help/getDeviceTokenForFirebase';

const Chat = () => {

    const scrollRef = useRef();
    const { ref, inView, entry } = useInView({
        threshold: 0.5
    });
    const dispatch = useDispatch();

    const postU = usePost();
    const rooms = useRooms();
    const userId = TokenStorage.getUser()?.id;
    const { users, messages, log, sendMessage, readMessage, joinRoom, onLoadNewMessages, newMessage } = useChat();

    const [lastItemId, setLastItemId] = useState();
    const [message, setMessage] = useState('');
    const [currentChat, setCurrentChat] = useState(null);
    const [currentMessages, setCurrentMessages] = useState([]);

    const [firstLoadChat, setFirstLoadChat] = useState(true);

    useEffect(() => {
        setCurrentMessages([
            ...messages,
            ...currentMessages
        ]);
        setLastItemId(messages[0]?.id);
        setTimeout(() => {
            document.getElementById(`${messages[messages.length - 1]?.id}`)?.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
                inline: 'nearest'
            });
        }, 300);
    }, [messages]);

    useEffect(() => {
        setCurrentMessages([
            ...currentMessages,
            ...newMessage
        ]);
        setTimeout(() => {
            scrollRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
        }, 100);
    }, [newMessage]);

    const onJoinRoom = async (currentRoom) => {
        await setFirstLoadChat(true);
        await setCurrentMessages([]);
        await setLastItemId(messages[0]?.id);
        await setCurrentChat(currentRoom);
        await joinRoom(currentRoom?.roomId);
    };

    const send = async (e) => {
        await e.preventDefault();
        const index = makeid(21);

        const messageToSend = {
            roomId: currentChat.roomId,
            messageType: 'text',
            textOrPathToFile: message,
            index: index

        };

        if (message !== '') {
            sendMessage(messageToSend);
            setMessage('');
        }

    };

    const sendImage = (event) => {
        const data = new FormData();
        data.append('file', event?.target?.files[0]);
        const index = makeid(21);

        postU(`chat/upload`, data)
            .then((response) => {
                if (response.status === 'success') {
                    const messageToSend = {
                        roomId: currentChat.roomId,
                        messageType: 'image',
                        textOrPathToFile: response.data.path,
                        index: index
                    };
                    sendMessage(messageToSend);
                }
            })
            .catch((e) => {
                console.log(e.response.data);
            })
            .finally(() => {

            });

    };

    const sendFile = (event) => {
        const data = new FormData();
        data.append('file', event?.target?.files[0]);
        const index = makeid(21);
        postU(`chat/upload`, data)
            .then((response) => {
                if (response.status === 'success') {
                    const messageToSend = {
                        roomId: currentChat.roomId,
                        messageType: 'file',
                        textOrPathToFile: response.data.path,
                        index: index
                    };
                    sendMessage(messageToSend);
                }
            })
            .catch((e) => {
                console.log(e.response.data);
            })
            .finally(() => {

            });
    };

    const sendVideo = (event) => {
        const data = new FormData();
        data.append('file', event?.target?.files[0]);
        const index = makeid(21);

        postU(`chat/upload`, data)
            .then((response) => {
                if (response.status === 'success') {
                    const messageToSend = {
                        roomId: currentChat.roomId,
                        messageType: 'video',
                        textOrPathToFile: response.data.path,
                        index: index
                    };
                    sendMessage(messageToSend);
                }
            })
            .catch((e) => {
                console.log(e.response.data);
            })
            .finally(() => {

            });
    };

    useEffect(() => {
        if (!firstLoadChat) {
            if (inView) {
                console.log('load data');
                onLoadNewMessages({
                    roomId: currentChat?.roomId,
                    lastMessageId: lastItemId,
                    limit: 10
                });
            }
        }
        setFirstLoadChat(false);

    }, [inView]);

    useEffect(() =>{
        getDeviceTokenForFirebase(dispatch);
    },[])

    return (
        <>
            <Helmet>
                <title>Чат</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                    Чат
                </Box>
            </Box>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '90%', py: 3 }}>
                <Container maxWidth={false}>

                    <SubscriptionLogic/>

                    <div className="messenger">
                        <div className="chatMenu">
                            <div className="chatMenuTitle">
                                История
                            </div>
                            <div
                                className={`chatMenuWrapper ${rooms?.length <= 0 ? 'chatMenuWrapperNothingToSee' : ''}`}>
                                {
                                    rooms?.length <= 0
                                        ?
                                        <div className="chatMenuWrapperNothingToSee">
                                            На данный момент нет активных чатов
                                        </div>
                                        :
                                        rooms?.map((currentRoom) => (
                                            <div onClick={() => onJoinRoom(currentRoom)}>
                                                <Conversation
                                                    isActiveChat={currentRoom?.roomId === currentChat?.roomId}
                                                    currentRoom={currentRoom}
                                                />
                                            </div>
                                        ))
                                }
                            </div>
                        </div>
                        <div className="chatBox">
                            {currentChat
                                ?
                                <div className="chatBoxWrapper">
                                    <ChatHeader
                                        goBack={() => setCurrentChat(null)}
                                        currentChat={currentChat}
                                    />
                                    <div className={message ? 'chatBoxTop' : 'chatBoxTopR'}>
                                        <div ref={ref} style={{ height: 20, width: '100%' }}/>

                                        {currentMessages?.map((message) => (
                                            <div ref={scrollRef}>
                                                <Message
                                                    key={message?.id}
                                                    message={message}
                                                    own={message?.userId === userId}
                                                />
                                            </div>
                                        ))}
                                    </div>

                                    <div className="chatInput">
                                        <Input
                                            message={message}
                                            setMessages={setMessage}
                                            sendFile={sendFile}
                                            sendVideo={sendVideo}
                                            sendImage={sendImage}
                                            send={send}
                                        />
                                    </div>
                                </div>
                                :
                                <span className="noConversationText">
                                   Откройте чат, чтобы начать переписку
                                </span>
                            }
                        </div>
                    </div>
                </Container>
            </Box>
        </>
    );
};

export default Chat;
