import './input.scss';
import moment from 'moment';
import 'moment/locale/ru';
import React, { useState } from 'react';
import PaperClip from '../../../icons/PaperClip';
import Camera from '../../../icons/Camera';
import Video from '../../../icons/Video';
import Send from '../../../icons/Send';

export default function Input({
                                  message,
                                  setMessages,
                                  sendImage,
                                  sendFile,
                                  sendVideo,
                                  send,
                                  ...props
                              }) {


    return (
        <form onSubmit={send}>
            <div className="chatBoxBottom">
                <div className="input-item__wrapper">
                    <div className="input-item">
                        <input
                            placeholder="Введите сообщение"
                            type="text"
                            value={message}
                            onChange={(event) => setMessages(event.target.value)}
                            {...props}
                        />
                    </div>
                </div>

                {message === ''
                    ?
                    <>
                        <div className="chat-item-load">
                            <input accept=".txt,.rtf,.odt,.doc,.docx,.pdf,.xls,.xlsx,.ppt,.pptx,.pptm" type="file"
                                   style={{ display: 'none' }}
                                   id={1}
                                   onChange={(event) => sendFile(event)}/>
                            <label htmlFor={1} className="pointer">
                                <PaperClip/>
                            </label>
                        </div>
                        <div className="chat-item-load">
                            <input accept="image/*" type="file" style={{ display: 'none' }}
                                   id={2}
                                   onChange={(event) => sendImage(event)}
                            />
                            <label htmlFor={2} className="pointer">
                                <Camera/>
                            </label>
                        </div>

                        <div className="chat-item-load">
                            <input accept="video/*" type="file" style={{ display: 'none' }}
                                   id={3}
                                   onChange={(event) => sendVideo(event)}
                            />
                            <label htmlFor={3} className="pointer">
                                <Video/>
                            </label>
                        </div>
                    </>
                    :
                    <button className="pointer" type="submit">
                        <Send/>
                    </button>
                }
            </div>
        </form>
    );
}
