import { Helmet } from 'react-helmet';
import {
    Box,
    Container,
    Button, TextField, CardContent
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useNavigate } from 'react-router-dom';
import { useGet, usePost, usePut } from '../../API/request';
import React, { useEffect, useState } from 'react';
import { BallTriangle } from 'react-loader-spinner';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Alert from '@material-ui/core/Alert';
import { EditorState, convertToRaw, ContentState, convertFromHTML  } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import Typography from '@material-ui/core/Typography';
import theme from '../../theme';
import { convertToHTML } from 'draft-convert';

const DishAdd = () => {

    const navigate = useNavigate();
    const postU = usePost();
    const getU = useGet();
    const putU = usePut();
    const [desc, setDesc] = useState("");

    const [editorState, setEditorState] = useState(
        () => EditorState.createWithContent(
            ContentState.createFromBlockArray(
                convertFromHTML(desc)
            )
        ),
    );

    const [types, setTypes] = useState([]);
    const [groups, setGroups] = useState([]);
    const [uploadedImg, setUploadedImg] = useState('/static/images/defphoto.jpg');

    const [isLoaded, setIsLoaded] = useState(true);

    const [values, setValues] = useState({
        name: '',
        type: '',
        calories: '',
        image: '',
        description: '',
        recipe: '',
        recipeDraft: '',
        isForBreakfast: '',
        mealGroupsIds: [],
        acceptableCombinations: '',
        incompatibleCombinations: '',
        specialCombinations: '',
        combinations: ''
    });
    const [errors, setErrors] = useState({
        name: false,
        type: false,
        calories: false,
        image: false,
        description: false,
        recipe: false,
        recipeDraft: false,
        isForBreakfast: false,
        mealGroupsIds: false,
        acceptableCombinations: false,
        incompatibleCombinations: false,
        specialCombinations: false,
        combinations: false
    });

    const [submitDisabled, setSubmitDisabled] = useState(false);

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

            setSubmitDisabled(false);
        }, 1400);
    };

    const avaUploaded = (event) => {
        setUploadedImg(URL.createObjectURL(event.target.files[0]));
        setValues({
            ...values,
            image: event.target.files[0]
        });
    };

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: false
        });
    };

    const validate = () => {
        let validComplete = true;
        let formErrors = { ...errors };

        if (values.name === '') {
            validComplete = false;
            formErrors.name = false;
            showAlert('error', 'Поле Название не должно быть пустым');
        }
        if (values.type === '') {
            validComplete = false;
            formErrors.type = false;
            showAlert('error', 'Поле Тип блюда не должно быть пустым');
        }
        if (values.mealGroupsIds.length < 0) {
            validComplete = false;
            formErrors.type = false;
            showAlert('error', 'Поле Группы блюда не должно быть пустым');
        }

        setErrors(formErrors);
        return validComplete;
    };

    const submit = async () => {
        if (validate()) {
            setSubmitDisabled(true);

            const data = new FormData();

            data.append('name', values.name);
            data.append('type', values.type);
            data.append('mealGroupsIds', JSON.stringify(values.mealGroupsIds));
            data.append('recipe', draftToHtml(convertToRaw(editorState.getCurrentContent())));

            if (values.image) {
                data.append('image', values.image);
            }
            if (values.calories) {
                data.append('calories', values.calories);
            }
            if (values.description) {
                data.append('description', values.description);
            }
            if (values.combinations) {
                data.append('combinations', values.combinations);
            }
            if (values.acceptableCombinations) {
                data.append('acceptableCombinations', values.acceptableCombinations);
            }
            if (values.incompatibleCombinations) {
                data.append('incompatibleCombinations', values.incompatibleCombinations);
            }
            if (values.specialCombinations) {
                data.append('specialCombinations', values.specialCombinations);
            }
            if (values.isForBreakfast) {
                data.append('isForBreakfast', values.isForBreakfast);
            }

            postU('meals', data)
                .then((resp) => {
                    if (resp.status === 'success') {
                        showAlert('success', 'Вы успешно добавили новое блюдо');
                    } else {
                        showAlert('error', 'Произошла ошибка при добавление блюда');
                    }
                })
                .catch((err) => {
                    showAlert('error', err.response.data.message);
                    setSubmitDisabled(false);
                })
                .finally(() => {

                });
        }
    };

    const getLists = () => {
        setIsLoaded(true);
        getU(`meals/types`)
            .then((resp) => {
                if (resp.status === 'success') {
                    setTypes(resp.data.mealType);
                } else {
                    showAlert('error', 'Произошла ошибка при загрузке типов блюд');
                }
            })
            .catch(() => {
                showAlert('error', 'Произошла ошибка при загрузке типов блюд');
            })
            .finally(() => {

            });
        getU(`meals/groups`)
            .then((resp) => {
                if (resp.status === 'success') {
                    setGroups(resp.data.mealGroups);
                } else {
                    showAlert('error', 'Произошла ошибка при загрузке групп блюд');
                }
            })
            .catch(() => {
                showAlert('error', 'Произошла ошибка при загрузке групп блюд');
            })
            .finally(() => {
                setIsLoaded(false);
            });
    };

    useEffect(() => {
        getLists();
    }, []);


    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Блюда</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Назад
                    </Button>
                    Блюда
                </Box>
                <ul className="headerList">
                    <li onClick={() => navigate(-1)}>Блюда</li>
                    <li>/</li>
                    <li>Добавление</li>
                </ul>
            </Box>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false}>
                    <Box sx={{ pt: 2 }}>
                        <form>
                            <Card>
                                <CardHeader
                                    title="Добавление нового блюда"
                                />
                                <Divider/>
                                <CardContent sx={{ position: 'relative' }}>

                                    <div className="itemWrapper">
                                        <div className="container">
                                            <input accept="xlsx/*" type="file" style={{ display: 'none' }}
                                                   id={1}
                                                   onChange={(event) => avaUploaded(event, 1)}/>
                                            <label htmlFor={1}>
                                                <img src={uploadedImg} className="itemImg"/>
                                                <div className="middle"/>
                                            </label>
                                        </div>
                                        <div className="help-text">
                                            Доступны следующие расширения: .png, .jpg, .gif
                                        </div>
                                    </div>
                                    <TextField
                                        fullWidth
                                        label="Название"
                                        margin="normal"
                                        name="name"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.name}
                                        variant="outlined"
                                        error={errors.name}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Описание"
                                        margin="normal"
                                        name="description"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.description}
                                        variant="outlined"
                                        error={errors.description}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Введите сочетания блюд/напиткой/ингредиентов через запятую"
                                        margin="normal"
                                        name="combinations"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.combinations}
                                        variant="outlined"
                                        error={errors.combinations}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Введите допустимые сочетания блюд/напиткой/ингредиентов через запятую"
                                        margin="normal"
                                        name="acceptableCombinations"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.acceptableCombinations}
                                        variant="outlined"
                                        error={errors.acceptableCombinations}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Введите особые сочетания блюд/напиткой/ингредиентов через запятую"
                                        margin="normal"
                                        name="specialCombinations"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.specialCombinations}
                                        variant="outlined"
                                        error={errors.specialCombinations}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Введите не допустимые сочетания блюд/напиткой/ингредиентов через запятую"
                                        margin="normal"
                                        name="incompatibleCombinations"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.incompatibleCombinations}
                                        variant="outlined"
                                        error={errors.incompatibleCombinations}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Калории"
                                        margin="normal"
                                        name="calories"
                                        onChange={handleChange}
                                        type="number"
                                        value={values.calories}
                                        variant="outlined"
                                        error={errors.calories}
                                    />

                                    <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                                        <InputLabel id="isForBreakfast">
                                            Для завтрака или нет
                                        </InputLabel>
                                        <Select
                                            labelId="isForBreakfast"
                                            name="isForBreakfast"
                                            value={values.isForBreakfast}
                                            label="Для обеда или нет"
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={true}>Да</MenuItem>
                                            <MenuItem value={false}>Нет</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                                        <InputLabel id="type">
                                            Выберите тип блюда
                                        </InputLabel>
                                        <Select
                                            labelId="type"
                                            name="type"
                                            value={values.type}
                                            label="Выберите тип блюда"
                                            onChange={handleChange}
                                        >
                                            {
                                                types?.map((item) => <MenuItem value={item}>{item}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                    <TextField
                                        style={{ marginTop: 10 }}
                                        select
                                        fullWidth
                                        name="mealGroupsIds"
                                        id="userRoles"
                                        variant="outlined"
                                        label="Выберите группы блюд"
                                        value={values.mealGroupsIds}
                                        SelectProps={{
                                            multiple: true,
                                            value: values.mealGroupsIds,
                                            onChange: handleChange
                                        }}
                                    >
                                        {
                                            groups?.map((item) => <MenuItem value={item.id}>{item.name}</MenuItem>)
                                        }
                                    </TextField>

                                    <Typography style={{ marginTop: 10, marginBottom: 10, fontSize: 20 }}>
                                        Рецепт
                                    </Typography>

                                    <Editor
                                        editorStyle={{
                                            border: '1px solid rgba(0,0,0,0.2)',
                                            minHeight: 500,
                                            padding: 10
                                        }}
                                        wrapperClassName="demo-wrapper"
                                        editorClassName="demo-editor"
                                        editorState={editorState}
                                        onEditorStateChange={setEditorState}
                                    />

                                    <Alert severity={alert.type}
                                           style={{ display: alert.isVisible ? 'flex' : 'none' }}>
                                        {alert.txt}
                                    </Alert>
                                </CardContent>
                                <Divider/>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={submit}
                                        disabled={submitDisabled}
                                    >
                                        Добавить
                                    </Button>
                                </Box>
                            </Card>
                        </form>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default DishAdd;
