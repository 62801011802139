import {IO} from "../index";
import {useEffect, useRef, useState} from "react";

export default function useRooms() {

    const [rooms, setRooms] = useState([]);

    const {current: socket} = useRef(IO);

    useEffect(() => {
        socket.emit("roomsList:get");

        socket.on("roomsList:update", (rooms) => {
            // console.log(rooms)
            setRooms(rooms);
        });
    }, []);

    return rooms;
}
