import React, { useState } from 'react';
import { Box, Button, Container } from '@material-ui/core';
import { BallTriangle } from 'react-loader-spinner';
import { Bell, BellOff } from 'react-feather';
import TokenStorage from '../../../API/TokenStorage';
import { setUser } from '../../../redux/slices/auth';
import { useDispatch, useSelector } from 'react-redux';
import { useDelete, useGet, usePut } from '../../../API/request';
import { getDeviceTokenForFirebase } from '../../../help/getDeviceTokenForFirebase';

const SubscriptionLogic = () => {


    const getU = useGet();
    const putU = usePut();
    const deleteU = useDelete();
    const dispatch = useDispatch();

    const isHasSubscribe = useSelector(state => state.auth.user?.deviceToken);
    const firebaseTokenRedux = useSelector((state) => state.auth.firebaseToken);
    const firebaseTokenStorage = TokenStorage.getFirebaseToken();

    const [isLoadingSubscribe, setIsLoadingSubscribe] = useState(false);

    const subscribed = () => {
        setIsLoadingSubscribe(true);
        putU('users/current/device', { deviceToken: firebaseTokenStorage || firebaseTokenRedux })
            .then((response) => {
                if (response.status === 'success') {
                    console.log('You have subscribed to notifications');
                    dispatch(setUser(response.data.user));
                    TokenStorage.setUser(response.data.user);
                }
            })
            .catch((e) => {
                console.log(e?.response?.data);
            })
            .finally(() => {
                setIsLoadingSubscribe(false);
            });
    };
    const unsubscribed = () => {
        setIsLoadingSubscribe(true);
        deleteU('users/current/device')
            .then((response) => {
                if (response.status === 'success') {
                    console.log('You have unsubscribed from notifications');
                    dispatch(setUser(response.data.user));
                    TokenStorage.setUser(response.data.user);
                }
            })
            .catch((e) => {
                console.log(e?.response?.data);
            })
            .finally(() => {
                setIsLoadingSubscribe(false);
            });
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '25px' }}>
            {isHasSubscribe
                ?
                <Button
                    color="error"
                    variant="contained"
                    disabled={isLoadingSubscribe}
                    startIcon={
                        isLoadingSubscribe
                            ? <BallTriangle
                                wrapperStyle={{
                                    marginBottom: 5
                                }}
                                height="15"
                                width="15"
                                color='blue'
                                ariaLabel='loading'
                            />
                            : <BellOff/>
                    }
                    onClick={unsubscribed}
                >
                    Отписатся от уведомлений
                </Button>
                :
                <Button
                    color="primary"
                    variant="contained"
                    disabled={isLoadingSubscribe}
                    startIcon={
                        isLoadingSubscribe
                            ? <BallTriangle
                                wrapperStyle={{
                                    marginBottom: 5
                                }}
                                height="15"
                                width="15"
                                color='blue'
                                ariaLabel='loading'
                            />
                            : <Bell/>
                    }
                    onClick={subscribed}
                >
                    Подписаться на уведомления
                </Button>
            }
        </Box>
    );
};

export default SubscriptionLogic;
