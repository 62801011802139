import { Helmet } from 'react-helmet';
import {
    Box,
    Container,
    Button, TextField, CardContent
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useNavigate } from 'react-router-dom';
import { useGet, usePost, usePut } from '../../../API/request';
import React, { useEffect, useState } from 'react';
import { BallTriangle } from 'react-loader-spinner';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Alert from '@material-ui/core/Alert';
import { EditorState, convertToRaw, ContentState, convertFromHTML  } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import Typography from '@material-ui/core/Typography';
import { convertToHTML } from 'draft-convert';

const SubscriptionTypesAdd = () => {

    const navigate = useNavigate();
    const postU = usePost();

    const [values, setValues] = useState({
        cost: '',
        duration: '',
        durationInMonths: '',
        discountPerc: '',
    });
    const [errors, setErrors] = useState({
        cost: false,
        duration: false,
        durationInMonths: false,
        discountPerc: false,
    });

    const [submitDisabled, setSubmitDisabled] = useState(false);

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

            setSubmitDisabled(false);
        }, 1400);
    };


    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: false
        });
    };

    const validate = () => {
        let validComplete = true;
        let formErrors = { ...errors };

        if (values.cost === '') {
            validComplete = false;
            formErrors.cost = false;
            showAlert('error', 'Поле Стоимость не должно быть пустым');
        }
        if (values.duration === '') {
            validComplete = false;
            formErrors.duration = false;
            showAlert('error', 'Поле Период(в днях) не должно быть пустым');
        }
        if (values.durationInMonths.length < 0) {
            validComplete = false;
            formErrors.durationInMonths = false;
            showAlert('error', 'Поле Период(в месяцах) не должно быть пустым');
        }
        if (values.discountPerc.length < 0) {
            validComplete = false;
            formErrors.type = false;
            showAlert('error', 'Поле Скидка не должно быть пустым');
        }


        setErrors(formErrors);
        return validComplete;
    };

    const submit = async () => {
        if (validate()) {
            setSubmitDisabled(true);

            const data ={
                cost: values.cost,
                duration: values.duration,
                durationInMonths: values.durationInMonths,
                discountPerc: values.discountPerc,
            }

            postU('subscriptions/types', data)
                .then((resp) => {
                    if (resp.status === 'success') {
                        setValues({
                            cost: '',
                            duration: '',
                            durationInMonths: '',
                            discountPerc: '',
                        })
                        showAlert('success', 'Вы успешно добавили новый тип подписки');
                    } else {
                        showAlert('error', 'Произошла ошибка при добавление типа подписки');
                    }
                })
                .catch((err) => {
                    showAlert('error', err.response.data.message);
                })
                .finally(() => {
                    setSubmitDisabled(false);
                });
        }
    };


    return (
        <>
            <Helmet>
                <title>Типы подписок</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Назад
                    </Button>
                    Типы подписок
                </Box>
                <ul className="headerList">
                    <li onClick={() => navigate(-1)}>Типы подписок</li>
                    <li>/</li>
                    <li>Добавление типа подписки</li>
                </ul>
            </Box>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false}>
                    <Box sx={{ pt: 2 }}>
                        <form>
                            <Card>
                                <CardHeader
                                    title="Добавление нового типа подписки"
                                />
                                <Divider/>
                                <CardContent sx={{ position: 'relative' }}>
                                    <TextField
                                        fullWidth
                                        label="Стоимость"
                                        margin="normal"
                                        name="cost"
                                        onChange={handleChange}
                                        type="number"
                                        value={values.cost}
                                        variant="outlined"
                                        error={errors.cost}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Период(в днях)"
                                        margin="normal"
                                        name="duration"
                                        onChange={handleChange}
                                        type="number"
                                        value={values.duration}
                                        variant="outlined"
                                        error={errors.duration}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Период(в месяцах)"
                                        margin="normal"
                                        name="durationInMonths"
                                        onChange={handleChange}
                                        type="number"
                                        value={values.durationInMonths}
                                        variant="outlined"
                                        error={errors.durationInMonths}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Скидка(в процентах)"
                                        margin="normal"
                                        name="discountPerc"
                                        onChange={handleChange}
                                        type="number"
                                        value={values.discountPerc}
                                        variant="outlined"
                                        error={errors.discountPerc}
                                    />

                                    <Alert severity={alert.type}
                                           style={{ display: alert.isVisible ? 'flex' : 'none' }}>
                                        {alert.txt}
                                    </Alert>
                                </CardContent>
                                <Divider/>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={submit}
                                        disabled={submitDisabled}
                                    >
                                        Добавить
                                    </Button>
                                </Box>
                            </Card>
                        </form>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default SubscriptionTypesAdd;
