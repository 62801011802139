import { Helmet } from 'react-helmet';
import {
    Box,
    Container,
    Button, TextField, CardContent
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useNavigate } from 'react-router-dom';
import { useGet, usePost, usePut } from '../../API/request';
import React, { useEffect, useState } from 'react';
import { BallTriangle } from 'react-loader-spinner';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Alert from '@material-ui/core/Alert';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import Typography from '@material-ui/core/Typography';
import theme from '../../theme';
import { convertToHTML } from 'draft-convert';
import ModalGetUser from '../../components/ModalGetUser/ModalGetUser';

const SubscriptionAdd = () => {

    const navigate = useNavigate();
    const postU = usePost();
    const getU = useGet();
    const putU = usePut();

    const [subscriptionTypes, setSubscriptionTypes] = useState([]);

    const [isLoaded, setIsLoaded] = useState(true);

    const [emailOwner, setEmailOwner] = useState('');
    const [modalStatus, setModalStatus] = useState({
        isOpen: false,
        field: null
    });

    const [values, setValues] = useState({
        subscriptionTypeId: '',
        userId: ''
    });
    const [errors, setErrors] = useState({
        subscriptionTypeId: false,
        userId: false
    });

    const [submitDisabled, setSubmitDisabled] = useState(false);

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

            setSubmitDisabled(false);
        }, 1400);
    };

    const handleChangeOwners = ({ email, id }) => {
        setValues({
            ...values,
            userId: id
        });
        setEmailOwner(email);
        setModalStatus({
            isOpen: false,
            field: null
        });
    };


    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: false
        });
    };

    const validate = () => {
        let validComplete = true;
        let formErrors = { ...errors };

        if (values.subscriptionTypeId === '') {
            validComplete = false;
            formErrors.subscriptionTypeId = false;
            showAlert('error', 'Поле Тип подписки не должно быть пустым');
        }
        if (values.userId === '') {
            validComplete = false;
            formErrors.userId = false;
            showAlert('error', 'Поле Пользователь не должно быть пустым');
        }

        setErrors(formErrors);
        return validComplete;
    };

    const submit = async () => {
        if (validate()) {
            setSubmitDisabled(true);

            const data = {
                subscriptionTypeId: values.subscriptionTypeId,
                userId: values.userId
            };

            postU('subscriptions/admin', data)
                .then((resp) => {
                    if (resp.status === 'success') {
                        showAlert('success', 'Вы успешно добавили подписку');
                    } else {
                        showAlert('error', 'Произошла ошибка при добавление блюда');
                    }
                })
                .catch((err) => {
                    showAlert('error', err.response.data.message);
                })
                .finally(() => {
                    setSubmitDisabled(false);

                });
        }
    };

    const getLists = () => {
        setIsLoaded(true);
        getU(`subscriptions/types`)
            .then((resp) => {
                if (resp.status === 'success') {
                    setSubscriptionTypes(resp.data.subscriptionTypes);
                } else {
                    showAlert('error', 'Произошла ошибка при загрузке типов блюд');
                }
            })
            .catch(() => {
                showAlert('error', 'Произошла ошибка при загрузке типов блюд');
            })
            .finally(() => {
                setIsLoaded(false);
            });
    };

    useEffect(() => {
        getLists();
    }, []);

console.log(values)

    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Управление подписками</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Назад
                    </Button>
                    Управление подписками
                </Box>
                <ul className="headerList">
                    <li onClick={() => navigate(-1)}>Управление подписками</li>
                    <li>/</li>
                    <li>Добавление подписки</li>
                </ul>
            </Box>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false}>
                    <Box sx={{ pt: 2 }}>
                        <form>
                            <Card>
                                <CardHeader
                                    title="Добавление подписке пользователю"
                                />
                                <Divider/>
                                <CardContent sx={{ position: 'relative' }}>

                                    <TextField
                                        fullWidth
                                        disabled
                                        label="Почта пользователя"
                                        margin="normal"
                                        name="owner"
                                        type="text"
                                        value={emailOwner}
                                        variant="outlined"
                                        error={errors.owner}
                                    />
                                    <Box
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            alignItems: 'flex-end'
                                        }}
                                    >
                                        <Button
                                            onClick={() => setModalStatus({
                                                isOpen: true,
                                                field: 'owner'
                                            })}
                                            color="primary"
                                            variant="contained"
                                        >
                                            Выберите пользователя
                                        </Button>
                                    </Box>


                                    <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                                        <InputLabel id="subscriptionTypeId">
                                            Выберите тип подписки(дни)
                                        </InputLabel>
                                        <Select
                                            labelId="subscriptionTypeId"
                                            name="subscriptionTypeId"
                                            value={values.type}
                                            label="Выберите тип подписки(дни)"
                                            onChange={handleChange}
                                        >
                                            {
                                                subscriptionTypes?.map((item) => <MenuItem value={item.id}>{item.duration}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>

                                    <Alert severity={alert.type}
                                           style={{ display: alert.isVisible ? 'flex' : 'none' }}>
                                        {alert.txt}
                                    </Alert>
                                </CardContent>
                                <Divider/>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={submit}
                                        disabled={submitDisabled}
                                    >
                                        Добавить
                                    </Button>
                                </Box>
                            </Card>
                        </form>
                    </Box>
                </Container>
                <ModalGetUser
                    isOpenModal={modalStatus}
                    setIsOpenModal={setModalStatus}
                    handleChangeOwners={handleChangeOwners}
                />
            </Box>
        </>
    );
};

export default SubscriptionAdd;
