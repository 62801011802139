import React from "react";

function Camera() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                fill="#42444A"
                d="M7 6v.75a.75.75 0 00.624-.334L7 6zm1.406-2.11l-.624-.415.624.416zm7.188 0l.624-.415-.624.416zM17 6l-.624.416A.75.75 0 0017 6.75V6zm-2.75 7.5A2.25 2.25 0 0112 15.75v1.5a3.75 3.75 0 003.75-3.75h-1.5zM12 15.75a2.25 2.25 0 01-2.25-2.25h-1.5A3.75 3.75 0 0012 17.25v-1.5zM9.75 13.5A2.25 2.25 0 0112 11.25v-1.5a3.75 3.75 0 00-3.75 3.75h1.5zM12 11.25a2.25 2.25 0 012.25 2.25h1.5A3.75 3.75 0 0012 9.75v1.5zM7.624 6.416l1.406-2.11-1.248-.831-1.406 2.109 1.248.832zM10.07 3.75h3.86v-1.5h-3.86v1.5zm4.9.557l1.406 2.109 1.248-.832-1.406-2.11-1.248.833zm-1.04-.557c.418 0 .808.209 1.04.557l1.248-.832A2.75 2.75 0 0013.93 2.25v1.5zm-4.9.557a1.25 1.25 0 011.04-.557v-1.5c-.92 0-1.778.46-2.288 1.225l1.248.832zM21.25 10v7h1.5v-7h-1.5zM18 20.25H6v1.5h12v-1.5zM2.75 17v-7h-1.5v7h1.5zM6 20.25A3.25 3.25 0 012.75 17h-1.5A4.75 4.75 0 006 21.75v-1.5zM21.25 17A3.25 3.25 0 0118 20.25v1.5A4.75 4.75 0 0022.75 17h-1.5zM18 6.75A3.25 3.25 0 0121.25 10h1.5A4.75 4.75 0 0018 5.25v1.5zM6 5.25A4.75 4.75 0 001.25 10h1.5A3.25 3.25 0 016 6.75v-1.5zm0 1.5h1v-1.5H6v1.5zm12-1.5h-1v1.5h1v-1.5z"
            />
            <circle cx="12" cy="6" r="1" fill="#42444A"/>
        </svg>
    );
}

export default Camera;
