import './message.css';
import moment from 'moment';
import 'moment/locale/ru';

export default function Message({ message, own, key }) {


    return (
        <div className={`message ${own ? 'own' : 'manager'}`} id={message?.id}>

            <div className={`messageInfoWrap ${own ? 'ownInfoWrap' : 'managerInfoWrap'}`}>
                <img
                    className="messageImg"
                    src={message?.user?.avatar
                        ? `${process.env.REACT_APP_API_URL}/uploads/avatars/${message?.user?.avatar}`
                        : '/static/images/DefaultUser.png'
                    }
                    alt=""
                />
                <p className={`messageText ${own ? 'ownText' : 'managerText'}`}>
                    {
                        message.messageType === 'text' && message.textOrPathToFile
                    }
                    {
                        message.messageType === 'image' &&
                        <a href={`${process.env.REACT_APP_API_URL}/uploads/chat/${message.textOrPathToFile}`}
                           target="_blank">
                            <img
                                className="messageImage"
                                src={`${process.env.REACT_APP_API_URL}/uploads/chat/${message.textOrPathToFile}`}
                                alt="img"
                            />
                        </a>
                    }
                    {
                        message.messageType === 'file' &&
                        <a href={`${process.env.REACT_APP_API_URL}/uploads/chat/${message.textOrPathToFile}`}
                           target="_blank">
                            {message.textOrPathToFile}
                        </a>
                    }
                    {
                        message.messageType === 'video' &&
                        <a href={`${process.env.REACT_APP_API_URL}/uploads/chat/${message.textOrPathToFile}`}
                           target="_blank">
                            {message.textOrPathToFile}
                        </a>
                    }

                    <span className="messageTime">
                        {moment(message.creationDate).format('HH.mm, DD.MM')}
                    </span>
                </p>
            </div>
        </div>
    );
}
