import { Navigate } from 'react-router-dom';
import DashboardLayout from './layouts/DashboardLayout';
import MainLayout from './layouts/MainLayout';
import Account from './pages/Account/Account';
import UserInfo from './pages/User/UserInfo';
import UserEdit from './pages/User/UserEdit';
import UserList from './pages/User/UserList';
import Login from './pages/Login/Login';
import NotFound from './pages/NotFound/NotFound';
import Logs from './pages/Systems/Logs/Logs';
import Dashboard from './pages/Dashboard/Dashboard';
import EmailTemplateList from './pages/Templates/Email-Templates/EmailTemplateList';
import EmailTemplateCreate from './pages/Templates/Email-Templates/EmailTemplateCreate';
import EmailTemplateEdit from './pages/Templates/Email-Templates/EmailTemplateEdit';
import CommonSettings from './pages/Systems/CommonSettings/CommonSettings';
import UpdateSystem from './pages/Systems/UpdateSystem/UpdateSystem';
import SettingsExampl from './pages/Settings/SubscriptionTypes/SubscriptionTypesList';
import UserAdd from './pages/User/UserAdd';
import EmailHistoryList from './pages/Templates/Email-Hstory/EmailHistoryList';
import FeedBackList from './pages/FeedBacks/FeedBackList';
import FeedBackEdit from './pages/FeedBacks/FeedBackEdit';
import SubscriptionsManagingList from './pages/SubscriptionsManaging/SubscriptionsManagingList';
import Chat from './pages/Chat/Chat';
import DishList from './pages/Dish/DishList';
import DishAdd from './pages/Dish/DishAdd';
import DishInfo from './pages/Dish/DishInfo';
import DishEdit from './pages/Dish/DishEdit';
import PaymentList from './pages/Payment/PaymentList';
import SubscriptionRenew from './pages/SubscriptionsManaging/SubscriptionRenew';
import SubscriptionAdd from './pages/SubscriptionsManaging/SubscriptionAdd';
import SubscriptionTypesList from './pages/Settings/SubscriptionTypes/SubscriptionTypesList';
import SubscriptionTypesAdd from './pages/Settings/SubscriptionTypes/SubscriptionTypesAdd';
import SubscriptionTypesEdit from './pages/Settings/SubscriptionTypes/SubscriptionTypesEdit';
import DeletedDish from './pages/Dish/DeletedDish';
import AppFluer from './pages/Settings/AppFluer/AppFluer';
import Prodamus from './pages/Settings/Prodamus/Prodamus';
import AppLogs from './pages/AppWork/AppLogs';
import DocumentEdit from './pages/Documents/DocumentEdit';
import DocumentAdd from './pages/Documents/DocumentAdd';
import DocumentsList from './pages/Documents/DocumentsList';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'account', element: <Account /> },
      { path: 'dashboard', element: <Dashboard /> },

      { path: 'user/:id', element: <UserInfo /> },
      { path: 'user/edit/:id', element: <UserEdit /> },
      { path: 'user/add', element: <UserAdd /> },
      { path: 'users', element: <UserList /> },

      {
        path: 'subscriptions-managing',
        element: <SubscriptionsManagingList />
      },
      {
        path: 'subscriptions-managing/renew/:id',
        element: <SubscriptionRenew />
      },
      { path: 'subscriptions-managing/add', element: <SubscriptionAdd /> },

      { path: 'chat', element: <Chat /> },

      { path: 'dish', element: <DishList /> },
      { path: 'dish/info/:id', element: <DishInfo /> },
      { path: 'dish/add', element: <DishAdd /> },
      { path: 'dish/edit/:id', element: <DishEdit /> },
      { path: 'dish/deleted', element: <DeletedDish /> },

      { path: 'payments', element: <PaymentList /> },

      { path: 'feedbacks/edit/:id', element: <FeedBackEdit /> },
      { path: 'feedbacks', element: <FeedBackList /> },

      { path: 'email-templates', element: <EmailTemplateList /> },
      { path: 'email-history', element: <EmailHistoryList /> },
      { path: 'email-templates/create', element: <EmailTemplateCreate /> },
      { path: 'email-templates/edit/:id', element: <EmailTemplateEdit /> },

      { path: 'documents/edit/:id', element: <DocumentEdit /> },
      { path: 'documents/add', element: <DocumentAdd /> },
      { path: 'documents', element: <DocumentsList /> },

      { path: 'app-logs', element: <AppLogs /> },

      { path: 'logs', element: <Logs /> },
      { path: 'common-settings', element: <CommonSettings /> },
      { path: 'update-system', element: <UpdateSystem /> },

      { path: 'subscription-types', element: <SubscriptionTypesList /> },
      { path: 'subscription-types/add', element: <SubscriptionTypesAdd /> },
      {
        path: 'subscription-types/edit/:id',
        element: <SubscriptionTypesEdit />
      },

      { path: 'app-fluer', element: <AppFluer /> },
      { path: 'prodamus', element: <Prodamus /> },

      { path: '', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
