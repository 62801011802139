import { useNavigate } from "react-router";
import {
    Box,
    Avatar,
    Container,
    Button,
    Card,
    CardContent,
    Divider,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Table,
    Typography,
    Alert,
    TextField,
    TableFooter,
    TablePagination,
    CardActionArea,
    CardMedia
  } from '@material-ui/core';

const DishInfoList = ({title, meals, ...rest}) => {
    const navigate = useNavigate();

    const redirectToMeal = (id) => {
        navigate(`/app/dish/info/${id}`)
      }

    return (
        <Box sx={{ backgroundColor: 'background.default', pt: 3, pb: 1 }}>
          <Container maxWidth={false}>
            <Card>
              <Typography
                style={{ padding: 20 }}
                color="textPrimary"
                variant="h4"
              >
                {title}
              </Typography>
              <CardContent sx={{ p: 3, display: 'flex', flexWrap: 'wrap' }}>
                {meals.map((meal) => {
                  return (
                    <Card
                      sx={{ width: 345, ml: 3, mb: 3 }}
                      onClick={() => redirectToMeal(meal.id)}
                    >
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          height="180"
                          image={
                            meal.imageUri
                              ? `${process.env.REACT_APP_API_URL}/uploads/meals/${meal?.imageUri}`
                              : '/static/images/defphoto.jpg'
                          }
                          alt="meal image"
                        />
                        <CardContent>
                          <Typography gutterBottom variant="h5" component="div">
                            {meal.name}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {meal.description}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  );
                })}
              </CardContent>
            </Card>
          </Container>
        </Box>
    );
};

export default DishInfoList;
