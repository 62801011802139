import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Avatar,
  Container,
  Button,
  Card,
  CardContent,
  Divider,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Typography,
  Alert,
  TextField,
  TableFooter,
  TablePagination,
  CardActionArea,
  CardMedia
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { Link, useParams, useNavigate, Navigate } from 'react-router-dom';
import { useGet } from '../../API/request';
import '../../styles/All.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BallTriangle } from 'react-loader-spinner';
import DishInfoList from '../../components/DishInfoList';

const DishInfo = () => {
  const navigate = useNavigate();
  const getU = useGet();
  const { id } = useParams();

  const [isLoaded, setIsLoaded] = useState(true);

  const [data, setData] = useState({});
  const [compatibleMeals, setCompatibleMeals] = useState([]);
  const [compatibleAdditions, setCompatibleAdditions] = useState([]);
  const [compatibleDrinks, setCompatibleDrinks] = useState([]);
  const [specialMeals, setSpecialMeals] = useState([]);

  const [alert, setAlert] = useState({
    txt: '',
    isVisible: false,
    type: 'error'
  });

  const showAlert = (type, text) => {
    setAlert({
      txt: text,
      type,
      isVisible: true
    });

    setTimeout(() => {
      setAlert({
        txt: text,
        type,
        isVisible: false
      });
    }, 2500);
  };

  useEffect(() => {
    setIsLoaded(true);
    getU(`meals/details/${id}`)
      .then((resp) => {
        if (resp.status === 'success') {
          setData(resp.data.meal);
          setCompatibleMeals(resp.data.compatibleMeals);
          setCompatibleAdditions(resp.data.compatibleAdditions);
          setCompatibleDrinks(resp.data.compatibleDrinks);
          setSpecialMeals(resp.data.specialMeals);
        } else {
          showAlert('error', 'Произошла ошибка при попытке получить блюдо');
        }
      })
      .catch(() => {
        showAlert('error', 'Произошла ошибка при попытке получить блюдо');
      })
      .finally(() => {
        setIsLoaded(false);
      });
  }, [id]);

  if (isLoaded) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Блюдо</title>
      </Helmet>
      <Box className="headerWrapper">
        <Box className="headerTitle">
          <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
            Назад
          </Button>
          {data?.name}
        </Box>
        <ul className="headerList">
          <li onClick={() => navigate(-1)}>Блюда</li>
          <li>/</li>
          <li>{data?.name}</li>
        </ul>
      </Box>

      <Box sx={{ backgroundColor: 'background.default', pt: 3, pb: 1 }}>
        <Container maxWidth={false}>
          <Alert
            severity={alert.type}
            style={{ display: alert.isVisible ? 'flex' : 'none' }}
          >
            {alert.txt}
          </Alert>
          <Card>
            <CardContent sx={{ p: 3 }}>
              <PerfectScrollbar>
                <div className="wrapAvatar">
                  <Box
                    component="img"
                    alt="img"
                    src={
                      data?.imageUri
                        ? `${process.env.REACT_APP_API_URL}/uploads/meals/${data?.imageUri}`
                        : '/static/images/defphoto.jpg'
                    }
                    className="avatar"
                  />
                  <div className="info-block">
                    <div className="wrap">
                      <div className="label">ID:</div>
                      <div className="text">{data?.id || '---'}</div>
                    </div>

                    <div className="wrap">
                      <div className="label">Название</div>
                      <div className="text">{data?.name || '---'}</div>
                    </div>

                    <div className="wrap">
                      <div className="label">Описание</div>
                      <div className="text">{data?.description || '---'}</div>
                    </div>

                    <div className="wrap">
                      <div className="label">Тип:</div>
                      <div className="text">{data?.type || '---'}</div>
                    </div>

                    <div className="wrap">
                      <div className="label">Группы:</div>
                      <div className="text">
                        {data?.mealGroups
                          ?.map((item) => item?.name)
                          ?.join(', ') || '---'}
                      </div>
                    </div>

                    <div className="wrap">
                      <div className="label">Калории:</div>
                      <div className="text">{data?.calories || '---'}</div>
                    </div>
                    <div className="wrap">
                      <div className="label">Удаленное ли блюдо:</div>
                      <div className="text">
                        {data?.isDeleted ? 'Да' : 'Нет' || '---'}
                      </div>
                    </div>

                    <div className="wrap">
                      <div className="label">Для завтрака:</div>
                      <div className="text">
                        {data?.isForBreakfast ? 'Да' : 'Нет' || '---'}
                      </div>
                    </div>

                    <div className="wrap">
                      <div className="label">Порция</div>
                      <div className="text">{data?.portion || '---'}</div>
                    </div>

                    <div className="wrap">
                      <div className="label">Сочетания:</div>
                      <div className="text">
                        {data?.combinationsString || '---'}
                      </div>
                    </div>
                    <div className="wrap">
                      <div className="label">Не сочитаемые блюда:</div>
                      <div className="text">
                        {data?.incompatibleCombinations || '---'}
                      </div>
                    </div>
                    <div className="wrap">
                      <div className="label">Допустимые сочетания:</div>
                      <div className="text">
                        {data?.acceptableCombinationsString || '---'}
                      </div>
                    </div>
                    <div className="wrap">
                      <div className="label">Особые сочетания:</div>
                      <div className="text">
                        {data?.specialCombinationsString || '---'}
                      </div>
                    </div>
                  </div>
                </div>
              </PerfectScrollbar>
            </CardContent>
          </Card>
        </Container>
      </Box>

      {data?.recipe && (
        <Box sx={{ backgroundColor: 'background.default', pt: 3, pb: 1 }}>
          <Container maxWidth={false}>
            <Card>
              <Typography
                style={{ padding: 20 }}
                color="textPrimary"
                variant="h4"
              >
                Рецепт
              </Typography>
              <CardContent sx={{ p: 3 }}>
                <PerfectScrollbar>
                  <div
                    className="defaultStyle"
                    dangerouslySetInnerHTML={{ __html: data.recipe }}
                  />
                </PerfectScrollbar>
              </CardContent>
            </Card>
          </Container>
        </Box>
      )}

      {compatibleMeals.length != 0 && (
        <DishInfoList title="Сочетаемые блюда" meals={compatibleMeals} />
      )}
      {compatibleAdditions.length != 0 && (
        <DishInfoList
          title="Сочетаемые ингредиенты"
          meals={compatibleAdditions}
        />
      )}
      {compatibleDrinks.length != 0 && (
        <DishInfoList title="Сочетаемые напитки" meals={compatibleDrinks} />
      )}

      {specialMeals.length != 0 && (
        <DishInfoList title="Особые блюда" meals={specialMeals} />
      )}
    </>
  );
};

export default DishInfo;
