import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useSearchParams } from 'react-router-dom';
import {
    Box,
    Container,
    Avatar,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    TableFooter,
    TablePagination,
    TextField,
    Divider, Alert
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../skeletons/UserListSkelet';
import { useDelete, useGet, usePost } from '../../API/request';
import { useConfirm } from '../../components/Confirm/index';
import { BallTriangle } from 'react-loader-spinner';
import '../../styles/All.css';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import ModalErrors from '../../components/ModalErrors/ModalErrors';

const DishList = () => {

    const confirm = useConfirm();
    const getU = useGet();
    const postU = usePost();
    const deleteU = useDelete();
    const [searchParams, setSearchParams] = useSearchParams();

    const [isLoaded, setIsLoaded] = useState(true);

    const [modalErrors, setModalErrors] = useState({
        isOpen: false,
        errors: null
    });

    const [disableLoadTable, setDisableLoadTable] = useState(false);
    const [loadedTable, setLoadedTable] = useState('');

    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState(0);

    const [queryParams, setQueryParams] = useState({
        name: searchParams.get('name') || '',
        type: searchParams.get('type') || '',
        page: searchParams.get('page') || 0,
        limit: searchParams.get('limit') || 10
    });

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });
        }, 2500);
    };

    const XMLUpLoaded = (event) => {
        setLoadedTable(event.target.files[0]);
    };

    const onPostTale = () => {
        if (loadedTable !== '') {
            setDisableLoadTable(true);
            console.log('here');
            let data = new FormData();
            data.append('table', loadedTable);

            postU(`xlsx/meals/combinations/`, data)
                .then((resp) => {
                    if (resp.status === 'success') {
                        showAlert('success', 'Вы успешно загрузили таблицу');
                        if (resp.data.errors) {
                            setModalErrors({
                                isOpen: true,
                                errors: resp.data.errors
                            });
                        }
                        setLoadedTable('');
                    } else {
                        showAlert('error', 'Произошла ошибка при попытке загрузить таблицу');
                    }
                })
                .catch((e) => {
                    showAlert('error', e.response.data.message);
                })
                .finally(() => {
                    setDisableLoadTable(false);
                });
        }
    };

    const handleChangeQueryParams = (event) => {
        setQueryParams({
            ...queryParams,
            [event.target.name]: event.target.value
        });
    };

    const handleFilterQueryParams = () => {
        const params = {};
        if (queryParams.name !== '') {
            params.name = queryParams.name;
        }
        if (queryParams.type !== '') {
            params.type = queryParams.type;
        }

        if (Object.keys(params).length !== 0) {
            // setSearchParams(params);
            setSearchParams({
                ...params,
                page: 0,
                limit: 10
            });
            setPage(0);
            setLimit(10);
        }
    };

    const loadUsers = () => {
        setIsLoaded(true);

        let endpoint = `meals?page=${Number(searchParams.get('page')) + 1}&limit=${Number(searchParams.get('limit'))}&isDeleted=0`;

        if (queryParams.name && searchParams.get('name') !== null) {
            endpoint += `&name=${searchParams.get('name')}`;
        } else{
            setQueryParams({
                ...queryParams,
                name: ''
            })
        }

        if (queryParams.type && searchParams.get('type') !== null ) {
            endpoint += `&type=${searchParams.get('type')}`;
        } else {
            setQueryParams({
                ...queryParams,
                type: ''
            })
        }

        getU(endpoint)
            .then((resp) => {
                if (resp.status === 'success') {
                    setData(resp.data.meals);
                    setCount(resp.data.currentCount || 0);
                    // setLimit(resp.data.limit)
                    // setPage(Number(resp.data.page))

                } else {
                    showAlert('error', 'Произошла ошибка при получении блюд, повторите попытку позже');
                }
            })
            .catch((err) => {
                console.log(err.response);
                setData([]);
                setCount(0);
            })
            .finally(() => {
                setIsLoaded(false);
            });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        if (queryParams.name !== '') {
            setSearchParams({
                name: queryParams.name,
                page: newPage,
                limit: Number(searchParams.get('limit')) === 0 ? 10 : Number(searchParams.get('limit'))
            });
        } else if (queryParams.type !== '') {
            setSearchParams({
                type: queryParams.type,
                page: newPage,
                limit: Number(searchParams.get('limit')) === 0 ? 10 : Number(searchParams.get('limit'))
            });
        } else {
            setSearchParams({
                page: newPage,
                limit: Number(searchParams.get('limit')) === 0 ? 10 : Number(searchParams.get('limit'))
            });
        }
    };


    const handleChangeLimit = (event) => {
        setLimit(event.target.value);
        setPage(0);
        if (queryParams.name !== '') {
            setSearchParams({
                name: queryParams.name,
                page: 1,
                limit: event.target.value
            });
        } else if (queryParams.type !== '') {
            setSearchParams({
                type: queryParams.type,
                page: 0,
                limit: event.target.value
            });
        } else {
            setSearchParams({
                page: 0,
                limit: event.target.value,
            });
        }

    };


    useEffect(() => {
        onPostTale();
    }, [loadedTable]);


    const onDelete = (id) => {
        confirm({
            title: 'Удаление',
            content: 'Вы уверены, что хотите удалить блюдо?',
            onConfirm: () => {
                deleteU(`meals/delete/${id}`)
                    .then((resp) => {
                        if (resp.status === 'success') {
                            loadUsers();
                        } else {
                            showAlert('error', 'Произошла ошибка при попытке удалить блюдо');
                        }
                    })
                    .catch((e) => {
                        showAlert('error', e.response.data.message);
                    });
            }
        });
    };

    useEffect(() => {
        if (queryParams.name === '') {
            searchParams.delete('name');
            setSearchParams(searchParams);
        }
        if (queryParams.type === '') {
            searchParams.delete('type');
            setSearchParams(searchParams);
        }
    }, [queryParams]);


    useEffect(() => {
        loadUsers();
    }, [searchParams]);


    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Блюда</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                    Блюда
                </Box>
            </Box>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
                <Container maxWidth={false}>
                    <Alert severity={alert.type}
                           style={{ display: alert.isVisible ? 'flex' : 'none', marginBottom: 10 }}>
                        {alert.txt}
                    </Alert>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Box sx={{ marginLeft: 2 }}>
                            <Input type="file"
                                   style={{ display: 'none' }}
                                   id="dishXMLTable"
                                   disabled={disableLoadTable}
                                   onChange={XMLUpLoaded}
                            />
                            <label htmlFor="dishXMLTable">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    component="span"
                                    disabled={disableLoadTable}
                                >
                                    Загрузить таблицу блюд
                                </Button>
                            </label>
                        </Box>
                        <Box sx={{ marginLeft: 2 }}>
                            <Link to="/app/dish/deleted">
                                <Button color="error" variant="contained">
                                    Удаленные блюда
                                </Button>
                            </Link>
                        </Box>
                        <Box sx={{ marginLeft: 2 }}>
                            <Link to="/app/dish/add">
                                <Button color="warning" variant="contained">
                                    Добавить блюдо
                                </Button>
                            </Link>
                        </Box>

                    </Box>
                    <Box sx={{ pt: 3 }}>
                        <Card>
                            <PerfectScrollbar>
                                <Box sx={{ minWidth: 1500 }}>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        mx: 2,
                                        mb: 1
                                    }}>
                                        <TextField
                                            fullWidth
                                            label="Название блюда"
                                            margin="normal"
                                            name="name"
                                            onChange={handleChangeQueryParams}
                                            type="text"
                                            value={queryParams.name}
                                            variant="outlined"
                                            style={{ marginRight: 10 }}
                                        />
                                        <FormControl
                                            fullWidth
                                            sx={{ mt: 2, mb: 1 }}
                                            style={{ marginRight: 10 }}
                                        >
                                            <InputLabel id="type">
                                                Тип блюда
                                            </InputLabel>
                                            <Select
                                                labelId="type"
                                                name="type"
                                                value={queryParams.type}
                                                label="Тип блюда"
                                                onChange={handleChangeQueryParams}
                                            >
                                                <MenuItem value='Все'>Все</MenuItem>
                                                <MenuItem value='Блюдо'>Блюдо</MenuItem>
                                                <MenuItem value='Рецепт'>Рецепт</MenuItem>
                                                <MenuItem value='Напиток'>Напиток</MenuItem>
                                                <MenuItem value='Ингредиент'>Ингредиент</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            onClick={handleFilterQueryParams}
                                            sx={{ mt: 2, mb: 1 }}
                                            style={{ minWidth: 120 }}
                                        >
                                            Применить
                                        </Button>
                                    </Box>
                                    <Divider/>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ width: 80 }}>
                                                    Id
                                                </TableCell>
                                                <TableCell>
                                                    Картинка
                                                </TableCell>
                                                <TableCell>
                                                    Название
                                                </TableCell>
                                                <TableCell>
                                                    Тип
                                                </TableCell>
                                                <TableCell>
                                                    Группы
                                                </TableCell>
                                                <TableCell>
                                                    Удаленное ли блюдо:
                                                </TableCell>
                                                <TableCell>
                                                    Калории
                                                </TableCell>
                                                <TableCell>
                                                    Для завтрака
                                                </TableCell>
                                                <TableCell>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data?.map((item) => (
                                                <TableRow hover key={item.id}>
                                                    <TableCell sx={{ width: 80 }}>
                                                        {item?.id}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Box sx={{ alignItems: 'center', display: 'flex' }}>
                                                            <Box
                                                                component="img"
                                                                sx={{
                                                                    height: 50,
                                                                    width: 50,
                                                                    maxHeight: { xs: 233, md: 167 },
                                                                    maxWidth: { xs: 350, md: 250 }
                                                                }}
                                                                alt="img"
                                                                src={
                                                                    item?.imageUri
                                                                        ? `${process.env.REACT_APP_API_URL}/uploads/meals/${item?.imageUri}`
                                                                        : '/static/images/defphoto.jpg'
                                                                }
                                                            />

                                                        </Box>
                                                    </TableCell>
                                                    <TableCell>
                                                        {item?.name || '---'}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item?.type || '---'}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item?.mealGroups?.map(item => item?.name)?.join(', ') || '---'}
                                                    </TableCell>
                                                    <TableCell>
                                                        {data?.isDeleted ? 'Да' : 'Нет' || '---'}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item?.calories || '---'}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item?.isForBreakfast ? 'Да' : 'Нет' || '---'}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Box sx={{ display: 'flex' }}>
                                                            <Box sx={{ display: 'flex' }}>
                                                                <Link to={`/app/dish/info/${item?.id}`}>
                                                                    <Button color="primary"
                                                                            variant="contained">
                                                                        Инфо.
                                                                    </Button>
                                                                </Link>
                                                                <Box sx={{ ml: 2 }}>
                                                                    <Link to={`/app/dish/edit/${item.id}`}>
                                                                        <Button
                                                                            color="primary"
                                                                            variant="contained"
                                                                        >
                                                                            Редакт.
                                                                        </Button>
                                                                    </Link>
                                                                </Box>
                                                                <Box sx={{ ml: 2 }}>
                                                                    <Button
                                                                        color="error" variant="contained"
                                                                        onClick={() => onDelete(item.id)}
                                                                    >
                                                                        Удалить
                                                                    </Button>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    labelRowsPerPage={
                                                        <span>Кол-во строк на странице:</span>}
                                                    rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                                    colSpan={7}
                                                    count={count}
                                                    page={Number(searchParams.get('page'))}
                                                    rowsPerPage={Number(searchParams.get('limit')) === 0 ? 10 : Number(searchParams.get('limit'))}
                                                    onPageChange={handleChangePage}
                                                    onRowsPerPageChange={handleChangeLimit}
                                                />
                                                {console.log(Number(searchParams.get('limit')))}
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </Box>
                            </PerfectScrollbar>
                        </Card>
                    </Box>
                </Container>
                <ModalErrors
                    isOpenModal={modalErrors}
                    setIsOpenModal={setModalErrors}
                />
            </Box>
        </>
    );
};

export default DishList;
