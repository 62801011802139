import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
    Box,
    Container,
    Card,
    Button,
    TextField,
    Divider,
    InputLabel,
    Select,
    Alert,
    Checkbox,
    FormControlLabel,
    CircularProgress,
    Typography,
    CardHeader,
    CardContent
} from '@material-ui/core';
import { useGet, usePut } from '../../../API/request';
import { BallTriangle } from 'react-loader-spinner';

const AppFluer = () => {

    const getU = useGet();
    const putU = usePut();

    const [isLoaded, setIsLoaded] = useState(true);
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const [values, setValues] = useState({
        value: ''
    });

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

            setSubmitDisabled(false);
        }, 1400);
    };


    useEffect(() => {
        setIsLoaded(true);
        getU('metric')
            .then((response) => {
                if (response.status === 'success') {
                    setValues({
                        value: response.data.metric
                    });
                } else {
                    showAlert('error', 'Произошла ошибка при попытке получить параметры appFluer');
                }
            })
            .catch((e) => {
                showAlert('error', e?.response?.data?.message);
            })
            .finally(() => {
                setIsLoaded(false);
            });
    }, []);

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    const submit = () => {
        setSubmitDisabled(true);

        putU('metric', { metric: values.value })
            .then((response) => {
                if (response.status === 'success') {
                    showAlert('success', 'Вы успешно обновили ключ appFluer');
                } else {
                    showAlert('error', 'Произошла ошибка при попытке обновить ключ appFluer');
                }
            })
            .catch((e) => {
                showAlert('error', e?.response?.data?.message);
            })
            .finally(() => {
                setSubmitDisabled(false);
            });
    };


    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }


    return (
        <>
            <Helmet>
                <title>Appsflyer</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
                <Container maxWidth={false}>
                    <Card>
                        <CardHeader title="Конфигурация Appsflyer"/>
                        <Divider/>
                        <CardContent sx={{ position: 'relative' }}>
                            <TextField
                                fullWidth
                                label="Ключь Appsflyer"
                                margin="normal"
                                name="value"
                                onChange={handleChange}
                                type="text"
                                value={values.value}
                                variant="outlined"
                            />
                            <Alert severity={alert.type}
                                   style={{ display: alert.isVisible ? 'flex' : 'none' }}>
                                {alert.txt}
                            </Alert>
                        </CardContent>
                        <Divider/>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={submit}
                                disabled={submitDisabled}
                            >
                                Сохранить
                            </Button>
                        </Box>
                    </Card>
                </Container>
            </Box>
        </>
    );
};

export default AppFluer;
