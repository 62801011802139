import axios from "axios";
import React, {useEffect, useState} from "react";
import "./styles.css";
import ArrowToLeft from '../../../icons/ArrowToLeft';

export default function ChatHeader({currentChat, goBack}) {

    return (
        <div className="chatBoxHeader">
            <div className="chatBoxHeaderBackIcon" onClick={goBack}>
                <ArrowToLeft/>
            </div>
            <img
                className="chatBoxHeaderImg"
                src={currentChat?.user?.avatar
                    ? `${process.env.REACT_APP_API_URL}/uploads/avatars/${currentChat?.user?.avatar}`
                    : '/static/images/DefaultUser.png'
                }
                alt=""
            />
            <div className="chatBoxHeaderInfoWrap">
                <div className="chatBoxHeaderInfoName">
                    {currentChat.user.email}
                </div>
                <div className="chatBoxHeaderInfoPosition">
                    {
                        currentChat.isOnline ? 'Online' : 'Offline'
                    }
                </div>
            </div>
        </div>
    );
}
