import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useSearchParams } from 'react-router-dom';
import {
    Box,
    Container,
    Avatar,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    TableFooter,
    TablePagination,
    TextField,
    Divider
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDelete, useGet } from '../../../API/request';
import { BallTriangle } from 'react-loader-spinner';
import '../../../styles/All.css';
import { useConfirm } from '../../../components/Confirm';

const SubscriptionTypesList = () => {

    const getU = useGet();
    const deleteU = useDelete();
    const confirm = useConfirm();

    const [isLoaded, setIsLoaded] = useState(true);

    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState(0);


    const loadUsers = () => {
        setIsLoaded(true);

        let endpoint = `subscriptions/types?page=${page + 1}&limit=${limit}`;

        getU(endpoint)
            .then((resp) => {
                if (resp.status === 'success') {
                    setData(resp.data.subscriptionTypes);
                    setCount(resp.data.totalCount || 0);
                }
            })
            .catch((err) => {
                console.log(err.response);
                setData([]);
                setCount(0);
            })
            .finally(() => {
                setIsLoaded(false);
            });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeLimit = (event) => {
        setLimit(event.target.value);
        setPage(0);
    };

    const onDelete = (id) => {

        confirm({
            title: 'Удаление',
            content: 'Вы уверены, что хотите удалить данный тип подписки?',
            onConfirm: () => {
                deleteU(`subscriptions/types/${id}`)
                    .then((resp) => {
                        if (resp.status === 'success') {
                            loadUsers();
                        }
                    })
                    .catch((e) => {
                        // console.log("opened")
                        // console.log(e.response)
                    });
            }
        });
    };


    useEffect(() => {
        loadUsers();
    }, [page, limit]);

    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Типы подписок</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                    Типы подписок
                </Box>
            </Box>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
                <Container maxWidth={false}>
                    <>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Box sx={{ marginLeft: 2 }}>
                                {/*<Link to="/app/subscription-types/add">*/}
                                <Button color="warning" variant="contained" disabled>
                                    Добавить тип подписки
                                </Button>
                                {/*</Link>*/}
                            </Box>
                        </Box>
                        <Box sx={{ pt: 3 }}>
                            <Card>
                                <PerfectScrollbar>
                                    <Box sx={{ minWidth: 600 }}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{ width: 80 }}>
                                                        Id
                                                    </TableCell>
                                                    <TableCell>
                                                        Стоимость
                                                    </TableCell>
                                                    <TableCell>
                                                        Скидка(в процентах)
                                                    </TableCell>
                                                    <TableCell>
                                                        Период(в днях)
                                                    </TableCell>
                                                    <TableCell>
                                                        Период(в месяцах)
                                                    </TableCell>
                                                    <TableCell>

                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {data?.map((item) => (
                                                    <TableRow hover key={item.id}>
                                                        <TableCell sx={{ width: 80 }}>
                                                            {item?.id}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item?.cost || '---'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item?.discountPerc || '---'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item?.duration || '---'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item?.durationInMonth || '---'}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Box style={{ display: 'flex', flexDirection: 'row' }}>
                                                                <Box style={{ marginRight: 10 }}>
                                                                    <Link to={`/app/subscription-types/edit/${item.id}`}>
                                                                        <Button
                                                                            color="primary"
                                                                            variant="contained"
                                                                        >
                                                                            Редакт.
                                                                        </Button>
                                                                    </Link>
                                                                </Box>
                                                                <Button
                                                                    color="error"
                                                                    variant="contained"
                                                                    onClick={() => onDelete(item?.id)}
                                                                    disabled
                                                                >
                                                                    Удалить
                                                                </Button>
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                            <TableFooter>
                                                <TableRow>
                                                    <TablePagination
                                                        labelRowsPerPage={
                                                            <span>Кол-во строк на странице:</span>}
                                                        rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                                        colSpan={7}
                                                        count={count}
                                                        rowsPerPage={limit}
                                                        page={page}
                                                        onPageChange={handleChangePage}
                                                        onRowsPerPageChange={handleChangeLimit}
                                                    />
                                                </TableRow>
                                            </TableFooter>
                                        </Table>
                                    </Box>
                                </PerfectScrollbar>
                            </Card>
                        </Box>
                    </>
                </Container>
            </Box>
        </>
    );
};

export default SubscriptionTypesList;
