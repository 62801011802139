import { initializeApp } from 'firebase/app';
import { firebaseConfig } from '../firebase';
import { getMessaging, getToken } from 'firebase/messaging';
import { setFirebaseToken } from '../redux/slices/auth';
import TokenStorage from '../API/TokenStorage';
import { useDispatch } from 'react-redux';

export const getDeviceTokenForFirebase = (dispatch) =>{


    Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
            console.log('Notification permission granted.');
            const app = initializeApp(firebaseConfig);

            const messaging = getMessaging(app);

            getToken(messaging, {
                vapidKey: 'BNKI6g8G-dfUzte7ch9hH6l2nZrnK9DIwAz4vniAAiCecIHcPTh7_eCHeGRRQT2yl-y2_krKPAAwEjRUUIp7ddw'
            })
                .then((currentToken) => {
                    console.log(currentToken);
                    if (currentToken) {
                        dispatch(setFirebaseToken(currentToken));
                        TokenStorage.setFirebaseToken(currentToken);
                    } else {
                        console.log('Can not get token');
                    }
                })
                .catch()
                .finally(() =>{});
        } else {
            console.log('Do not have permission!');
        }
    });
}
