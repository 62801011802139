import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import {
    Box,
    Container,
    Avatar,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    TableFooter,
    TablePagination,
    TextField,
    Divider, Alert
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../skeletons/UserListSkelet';
import { post, useDelete, useGet, usePost } from '../../API/request';
import { useConfirm } from '../../components/Confirm/index';
import { BallTriangle } from 'react-loader-spinner';
import '../../styles/All.css';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

const DeletedDish = () => {

    const navigate = useNavigate();
    const confirm = useConfirm();
    const getU = useGet();
    const postU = usePost();
    const [searchParams, setSearchParams] = useSearchParams();

    const [isLoaded, setIsLoaded] = useState(true);

    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState(0);

    const [queryParams, setQueryParams] = useState({
        name: searchParams.get('name') || '',
        type: searchParams.get('type') || ''
    });

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });
        }, 2500);
    };

    const handleChangeQueryParams = (event) => {
        setQueryParams({
            ...queryParams,
            [event.target.name]: event.target.value
        });
    };

    const handleFilterQueryParams = () => {
        const params = {};
        if (queryParams.name !== '') {
            params.name = queryParams.name;
        }
        if (queryParams.type !== '') {
            params.type = queryParams.type;
        }

        if (Object.keys(params).length !== 0) {
            setSearchParams(params);
        }
    };

    const loadUsers = () => {
        setIsLoaded(true);

        let endpoint = `meals?page=${page + 1}&limit=${limit}&isDeleted=1`;

        if (queryParams.name !== '') {
            endpoint += `&name=${searchParams.get('name')}`;
        }

        if (queryParams.type !== '') {
            endpoint += `&type=${searchParams.get('type')}`;
        }

        getU(endpoint)
            .then((resp) => {
                if (resp.status === 'success') {
                    setData(resp.data.meals);
                    setCount(resp.data.currentCount || 0);
                } else {
                    showAlert('error', 'Произошла ошибка при получении блюд, повторите попытку позже');
                }
            })
            .catch((err) => {
                console.log(err.response);
                setData([]);
                setCount(0);
            })
            .finally(() => {
                setIsLoaded(false);
            });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeLimit = (event) => {
        setLimit(event.target.value);
        setPage(0);
    };


    const onDelete = (id) => {
        confirm({
            title: 'Восстановление',
            content: 'Вы уверены, что хотите восстановить блюдо?',
            onConfirm: () => {
                postU(`meals/recover/${id}`)
                    .then((resp) => {
                        if (resp.status === 'success') {
                            loadUsers();
                        } else {
                            showAlert('error', 'Произошла ошибка при попытке восстановить блюдо');
                        }
                    })
                    .catch((e) => {
                        showAlert('error', e.response.data.message);
                    });
            }
        });
    };

    useEffect(() => {
        if (queryParams.name === '') {
            searchParams.delete('name');
            setSearchParams(searchParams);
        }
    }, [queryParams]);

    useEffect(() => {
        loadUsers();
    }, [page, limit, searchParams]);

    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Блюда</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate('/app/dish')}>
                        Назад
                    </Button>
                    Блюда
                </Box>
                <ul className="headerList">
                    <li onClick={() => navigate('/app/dish')}>Блюда</li>
                    <li>/</li>
                    <li>Удаленные блюда</li>
                </ul>
            </Box>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
                <Container maxWidth={false}>
                    <Alert severity={alert.type} style={{ display: alert.isVisible ? 'flex' : 'none' }}>
                        {alert.txt}
                    </Alert>
                    <Box sx={{ pt: 3 }}>
                        <Card>
                            <PerfectScrollbar>
                                <Box sx={{ minWidth: 1500 }}>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        mx: 2,
                                        mb: 1
                                    }}>
                                        <TextField
                                            fullWidth
                                            label="Название блюда"
                                            margin="normal"
                                            name="name"
                                            onChange={handleChangeQueryParams}
                                            type="text"
                                            value={queryParams.name}
                                            variant="outlined"
                                            style={{ marginRight: 10 }}
                                        />
                                        <FormControl
                                            fullWidth
                                            sx={{ mt: 2, mb: 1 }}
                                            style={{ marginRight: 10 }}
                                        >
                                            <InputLabel id="type">
                                                Тип блюда
                                            </InputLabel>
                                            <Select
                                                labelId="type"
                                                name="type"
                                                value={queryParams.type}
                                                label="Тип блюда"
                                                onChange={handleChangeQueryParams}
                                            >
                                                <MenuItem value='Все'>Все</MenuItem>
                                                <MenuItem value='Блюдо'>Блюдо</MenuItem>
                                                <MenuItem value='Рецепт'>Рецепт</MenuItem>
                                                <MenuItem value='Напиток'>Напиток</MenuItem>
                                                <MenuItem value='Ингридиент'>Ингридиент</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            onClick={handleFilterQueryParams}
                                            sx={{ mt: 2, mb: 1 }}
                                            style={{ minWidth: 120 }}
                                        >
                                            Применить
                                        </Button>
                                    </Box>
                                    <Divider/>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ width: 80 }}>
                                                    Id
                                                </TableCell>
                                                <TableCell>
                                                    Картинка
                                                </TableCell>
                                                <TableCell>
                                                    Название
                                                </TableCell>
                                                <TableCell>
                                                    Тип
                                                </TableCell>
                                                <TableCell>
                                                    Группы
                                                </TableCell>
                                                <TableCell>
                                                    Удаленное ли блюдо:
                                                </TableCell>
                                                <TableCell>
                                                    Калории
                                                </TableCell>
                                                <TableCell>
                                                    Для завтрака
                                                </TableCell>
                                                <TableCell>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data?.map((item) => (
                                                <TableRow hover key={item.id}>
                                                    <TableCell sx={{ width: 80 }}>
                                                        {item?.id}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Box sx={{ alignItems: 'center', display: 'flex' }}>
                                                            <Box
                                                                component="img"
                                                                sx={{
                                                                    height: 50,
                                                                    width: 50,
                                                                    maxHeight: { xs: 233, md: 167 },
                                                                    maxWidth: { xs: 350, md: 250 }
                                                                }}
                                                                alt="img"
                                                                src={
                                                                    item?.imageUri
                                                                        ? `${process.env.REACT_APP_API_URL}/uploads/meals/${item?.imageUri}`
                                                                        : '/static/images/defphoto.jpg'
                                                                }
                                                            />

                                                        </Box>
                                                    </TableCell>
                                                    <TableCell>
                                                        {item?.name || '---'}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item?.type || '---'}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item?.mealGroups?.map(item => item?.name)?.join(', ') || '---'}
                                                    </TableCell>
                                                    <TableCell>
                                                        {data?.isDeleted ? 'Да' : 'Нет' || '---'}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item?.calories || '---'}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item?.isForBreakfast ? 'Да' : 'Нет' || '---'}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Button
                                                            color="success" variant="contained"
                                                            onClick={() => onDelete(item.id)}
                                                        >
                                                            Восстановить
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    labelRowsPerPage={
                                                        <span>Кол-во строк на странице:</span>}
                                                    rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                                    colSpan={7}
                                                    count={count}
                                                    rowsPerPage={limit}
                                                    page={page}
                                                    onPageChange={handleChangePage}
                                                    onRowsPerPageChange={handleChangeLimit}
                                                />
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </Box>
                            </PerfectScrollbar>
                        </Card>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default DeletedDish;
