import React from "react";

function PaperClip() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="20"
            fill="none"
            viewBox="0 0 18 20"
        >
            <path
                stroke="#42444A"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M10.879 6.375L5.393 11.86a2.116 2.116 0 000 2.992v0a2.116 2.116 0 002.992 0l7.232-7.232a3.879 3.879 0 000-5.486v0a3.879 3.879 0 00-5.486 0L2.899 9.367a5.642 5.642 0 000 7.98v0a5.642 5.642 0 007.98 0l4.389-4.39"
            />
        </svg>
    );
}

export default PaperClip;
