import axios from 'axios';
import { useEffect, useState } from 'react';
import './conversation.css';
import ArrowToRight from '../../../icons/ArrowToRight';

export default function Conversation({ currentRoom, isActiveChat = false }) {

    const splicedLatMessage = currentRoom?.lastMessage?.textOrPathToFile?.length > 29
        ? currentRoom?.lastMessage?.textOrPathToFile.slice(0,29).concat('...')
        : currentRoom?.lastMessage?.textOrPathToFile

    return (
        <div className={`conversation ${isActiveChat && 'conversationActive'}`}>
            <div className="conversation-main-wrap">
                <div className="conversation-image__wrap">
                    <img
                        className="conversationImg"
                        src={currentRoom?.user?.avatar
                            ? `${process.env.REACT_APP_API_URL}/uploads/avatars/${currentRoom?.user?.avatar}`
                            : '/static/images/DefaultUser.png'
                        }
                        alt=""
                    />
                    {currentRoom?.isOnline
                        ?
                        <div className="isOnline__wrap">
                            <div className="conversation-isOnline"/>
                        </div>
                        : null
                    }

                </div>
                <div className="conversationInfo">
                    <span className="conversationName">{currentRoom?.user?.email || '---'}</span>
                    <span className="conversationPhone">{splicedLatMessage}</span>
                </div>
            </div>
            <ArrowToRight/>
        </div>
    );
}
