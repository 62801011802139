import React from "react";

function Send() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
        >
            <g clipPath="url(#clip0_61_3069)">
                <path
                    fill="#42444A"
                    d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2 .01 7z"
                />
            </g>
            <defs>
                <clipPath id="clip0_61_3069">
                    <path fill="#fff" d="M0 0H24V24H0z"/>
                </clipPath>
            </defs>
        </svg>
    );
}

export default Send;
