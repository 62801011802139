import React from 'react';
import Modal from '@material-ui/core/Modal';
import {
    Alert,
    Box,
    Button, Card, Container, Divider, Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow, TextField
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Typography from '@material-ui/core/Typography';

const ModalErrors = ({ isOpenModal, setIsOpenModal }) => {
    return (
        <Modal
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                overflow: 'scroll',
                height: '110%',
                display: 'block'
            }}
            open={isOpenModal.isOpen}
            onClose={() => setIsOpenModal({
                isOpen: false,
                errors: []
            })}
        >
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
                <Container maxWidth={false}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => setIsOpenModal({
                                isOpen: false,
                                errors: []
                            })}
                        >
                            Закрыть
                        </Button>
                    </Box>
                    <Box sx={{ pt: 3, mb: 15 }}>
                        <Card>
                            <PerfectScrollbar>
                                <Box>
                                    <Box style={{
                                        backgroundColor: 'rgb(248,3,3, 0.5)',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}>
                                        <Typography style={{padding: 10, fontSize: 18}}>
                                            Ошибки возникшие во время загрузки таблицы блюд
                                        </Typography>
                                    </Box>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    Тип блюда
                                                </TableCell>
                                                <TableCell>
                                                    Блюдо
                                                </TableCell>
                                                <TableCell>
                                                    Ошибка
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {isOpenModal?.errors?.map((item) => (
                                                <TableRow hover key={item.id}>
                                                    <TableCell>
                                                        {item?.sheet || '---'}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item?.meal || '---'}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item?.error || '---'}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Box>
                            </PerfectScrollbar>
                        </Card>
                    </Box>
                </Container>
            </Box>
        </Modal>
    );
};

export default ModalErrors;
