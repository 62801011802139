import { Helmet } from 'react-helmet';
import {
    Box,
    Container,
    Button, TextField, CardContent
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useNavigate, useParams } from 'react-router-dom';
import { useGet, usePost, usePut } from '../../API/request';
import React, { useEffect, useState } from 'react';
import { BallTriangle } from 'react-loader-spinner';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Alert from '@material-ui/core/Alert';
import { EditorState, convertToRaw, ContentState, convertFromHTML  } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import Typography from '@material-ui/core/Typography';
import theme from '../../theme';
import { convertToHTML } from 'draft-convert';

const SubscriptionRenew = () => {

    const {id} = useParams();
    const navigate = useNavigate();
    const postU = usePost();
    const putU = usePut();

    const [values, setValues] = useState({
        days: 0,
    });
    const [errors, setErrors] = useState({
        days: false,

    });

    const [submitDisabled, setSubmitDisabled] = useState(false);

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

            setSubmitDisabled(false);
        }, 1400);
    };

    const avaUploaded = (event) => {
        setUploadedImg(URL.createObjectURL(event.target.files[0]));
        setValues({
            ...values,
            image: event.target.files[0]
        });
    };

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: false
        });
    };

    const validate = () => {
        let validComplete = true;
        let formErrors = { ...errors };

        if (values.days < 0) {
            validComplete = false;
            formErrors.name = false;
            showAlert('error', 'Поле Дни для продления не должно быть пустым');
        }


        setErrors(formErrors);
        return validComplete;
    };

    const submit = async () => {
        if (validate()) {
            setSubmitDisabled(true);

            putU(`subscriptions/${id}`, {days: values.days})
                .then((resp) => {
                    if (resp.status === 'success') {
                        showAlert('success', 'Вы успешно продлили подписку пользователю');
                    } else {
                        showAlert('error', 'Произошла ошибка при продлении подписки');
                    }
                })
                .catch((err) => {
                    showAlert('error', err.response.data.message);
                    setSubmitDisabled(false);
                })
                .finally(() => {

                });
        }
    };


    return (
        <>
            <Helmet>
                <title>Блюда</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Назад
                    </Button>
                    Управлени подписками
                </Box>
                <ul className="headerList">
                    <li onClick={() => navigate(-1)}>Управлени подписками</li>
                    <li>/</li>
                    <li>Продление подписки</li>
                </ul>
            </Box>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false}>
                    <Box sx={{ pt: 2 }}>
                        <form>
                            <Card>
                                <CardHeader
                                    title="Продление подписки пользователя"
                                />
                                <Divider/>
                                <CardContent sx={{ position: 'relative' }}>

                                    <TextField
                                        fullWidth
                                        label="Дни для продления подписки"
                                        margin="normal"
                                        name="days"
                                        onChange={handleChange}
                                        type="number"
                                        value={values.days}
                                        variant="outlined"
                                        error={errors.days}
                                    />
                                    <Alert severity={alert.type}
                                           style={{ display: alert.isVisible ? 'flex' : 'none' }}>
                                        {alert.txt}
                                    </Alert>
                                </CardContent>
                                <Divider/>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={submit}
                                        disabled={submitDisabled}
                                    >
                                        Добавить
                                    </Button>
                                </Box>
                            </Card>
                        </form>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default SubscriptionRenew;
