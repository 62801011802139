import React from "react";

function Video() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
        >
            <g clipPath="url(#clip0_1013_772)">
                <path
                    fill="#42444A"
                    d="M24 6.255a1.583 1.583 0 00-.87-1.425 1.5 1.5 0 00-1.598.112L18 7.2V4.5A1.5 1.5 0 0016.5 3H3a3 3 0 00-3 3v12a3 3 0 003 3h13.5a1.5 1.5 0 001.5-1.5v-2.7l3.48 2.25a1.552 1.552 0 001.65.15 1.582 1.582 0 00.87-1.455V6.255zm-1.552 11.573c-.046 0-.083 0-.143-.046L16.5 14.078V19.5H3A1.5 1.5 0 011.5 18V6A1.5 1.5 0 013 4.5h13.5v5.422l5.85-3.75a.082.082 0 01.098 0 .083.083 0 01.052.083v11.49a.083.083 0 01-.052.082z"
                />
            </g>
            <defs>
                <clipPath id="clip0_1013_772">
                    <path fill="#fff" d="M0 0H24V24H0z"/>
                </clipPath>
            </defs>
        </svg>
    );
}

export default Video;
