import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useSearchParams } from 'react-router-dom';
import {
    Box,
    Container,
    Avatar,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    TableFooter,
    TablePagination,
    TextField,
    Divider, Alert
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../skeletons/UserListSkelet';
import { useDelete, useGet } from '../../API/request';
import { useConfirm } from '../../components/Confirm/index';
import { BallTriangle } from 'react-loader-spinner';
import '../../styles/All.css';
import moment from 'moment';

const PaymentList = () => {

    const getU = useGet();
    const [searchParams, setSearchParams] = useSearchParams();

    const [isLoaded, setIsLoaded] = useState(true);

    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState(0);

    const [queryParams, setQueryParams] = useState({
        email: searchParams.get('email') || '',
    });

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });
        }, 2500);
    };

    const handleChangeQueryParams = (event) => {
        setQueryParams({
            ...queryParams,
            [event.target.name]: event.target.value
        });
    };

    const handleFilterQueryParams = () => {
        const params = {};

        if (queryParams.email !== '') {
            params.email = queryParams.email;
        }
        console.log(Object.keys(params).length !== 0);
        if (Object.keys(params).length !== 0) {
            setSearchParams(params);
        }
    };

    const loadUsers = () => {
        setIsLoaded(true);

        let endpoint = `payments?page=${page + 1}&limit=${limit}`;

        if (queryParams.email !== '') {
            endpoint += `&email=${searchParams.get('email')}`;
        }
        getU(endpoint)
            .then((resp) => {
                if (resp.status === 'success') {
                    setData(resp.data.payments);
                    setCount(resp.data.currentCount || 0);
                } else {
                    showAlert('error', 'Произошла ошибка при попытке получить платежей');
                }
            })
            .catch((err) => {
                console.log(err.response);
                setData([]);
                setCount(0);
                showAlert('error', err.response.data.message);
            })
            .finally(() => {
                setIsLoaded(false);
            });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeLimit = (event) => {
        setLimit(event.target.value);
        setPage(0);
    };


    useEffect(() => {
        if (queryParams.email === '') {
            searchParams.delete('email');
            setSearchParams(searchParams);
        }
    }, [queryParams]);

    useEffect(() => {
        loadUsers();
    }, [page, limit, searchParams]);

    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Платежи</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                    Платежи
                </Box>
            </Box>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
                <Container maxWidth={false}>
                    <Alert severity={alert.type} style={{ display: alert.isVisible ? 'flex' : 'none' }}>
                        {alert.txt}
                    </Alert>
                    <>
                        <Box sx={{ pt: 3 }}>
                            <Card>
                                <PerfectScrollbar>
                                    <Box sx={{ minWidth: 1400 }}>
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            mx: 2,
                                            mb: 1
                                        }}>
                                            <TextField
                                                fullWidth
                                                label="Email"
                                                margin="normal"
                                                name="email"
                                                onChange={handleChangeQueryParams}
                                                type="text"
                                                value={queryParams.email}
                                                variant="outlined"
                                                style={{ marginRight: 10 }}
                                            />

                                            <Button
                                                color="primary"
                                                variant="contained"
                                                onClick={handleFilterQueryParams}
                                                sx={{ mt: 2, mb: 1 }}
                                            >
                                                Применить
                                            </Button>
                                        </Box>
                                        <Divider/>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{ width: 80 }}>
                                                        Id
                                                    </TableCell>
                                                    <TableCell sx={{ width: 120 }}>
                                                        Prodamus Id
                                                    </TableCell>
                                                    <TableCell>
                                                        Почта пользователя
                                                    </TableCell>
                                                    <TableCell>
                                                        Статус
                                                    </TableCell>
                                                    <TableCell>
                                                        Сумма
                                                    </TableCell>
                                                    <TableCell>
                                                        Дата
                                                    </TableCell>

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {data?.map((item) => (
                                                    <TableRow hover key={item.id}>
                                                        <TableCell sx={{ width: 80 }}>
                                                            {item?.id}
                                                        </TableCell>
                                                        <TableCell sx={{ width: 80 }}>
                                                            {item?.prodamusPaymentId || '---'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item?.user?.email || '---'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item?.status || '---'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item?.amount || '---'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {moment(item.date).format('HH:mm, DD.MM.YYYY') || '---'}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                            <TableFooter>
                                                <TableRow>
                                                    <TablePagination
                                                        labelRowsPerPage={
                                                            <span>Кол-во строк на странице:</span>}
                                                        rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                                        colSpan={7}
                                                        count={count}
                                                        rowsPerPage={limit}
                                                        page={page}
                                                        onPageChange={handleChangePage}
                                                        onRowsPerPageChange={handleChangeLimit}
                                                    />
                                                </TableRow>
                                            </TableFooter>
                                        </Table>
                                    </Box>
                                </PerfectScrollbar>
                            </Card>
                        </Box>
                    </>
                </Container>
            </Box>
        </>
    );
};

export default PaymentList;
